.sign-btn {
  background: #F2F2F7;
  border-radius: 14px !important;
  width: 119px;
  height: 54px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: center !important;
  letter-spacing: 0.1px;
  color: #1771C7;
}

.login-btn {
  background: #F2F2F7;
  border-radius: 14px !important;
  width: 199.16px;
  height: 54px;
  flex-shrink: 0;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: center !important;
  letter-spacing: 0.1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1771C7;
}

.logo-text {
  color: #1771C7;
  font-weight: bold;
  font-size: 35px;
}

.nav-content {
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  justify-content: center;
}

.nav-content > a {
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  position: relative;
  align-self: center;
  width: fit-content;
}

.nav-content > a::after {
   height: 5px;
   content: "";
   position: absolute;
   background-color: rgb(23, 113, 199);
   width: 0;
   left: 0;
   bottom: -5px;
   transition: width 0.5s;
 }

.nav-content > a.selected::after , .nav-content > a:hover::after  {
   width: 100%;
}
@media (min-width: 320px) and (max-width: 991px) {
  .logo-text {
    display: none;
  }
  .sign-btn, .login-btn {
    background-color: white;
    color: black;
  }
  .translate-mark {
    display: none;
  }

}
