@import '~antd/dist/antd.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-multi-carousel/lib/styles.css';



.big-title {
    font-size: 36px !important;
    line-height: 55px !important;
    font-weight: 700 !important;
}

.second-title {
    font-size: 22px !important;
    line-height: 40px !important;
    font-weight: 700 !important;
}

.second-text {
    font-size: 22px !important;
    line-height: 40px !important;
    font-weight: 400 !important;
}

.normal-text {
    font-size: 15px !important;
    line-height: 25px !important;
    font-weight: 400 !important;
}

.btn-text {
    font-size: 15px !important;
    font-weight: 700 !important;
}

body {
    font-family: "Nunito Sans" !important;
}

.ant-message {
    z-index: 10000001;
}
