.emojis-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
}

.moving-emoji {
  border-radius: 50%;
  position: relative;
  width: 32px;
  height: 32px;
  background-color: #ffeb96;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 5px -2px;
  cursor: pointer;
}

.emoji1::after,
.emoji1::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 3px;
  background-color: #414141;
  border-radius: 50%;
  top: 15px;
  animation: emoji1-face-move 5s ease-in-out infinite,
    emoji1-eyes-blink 2s ease-in-out infinite;
  animation-direction: alternate;
}

.emoji1::after {
  left: 10px;
}

.emoji1::before {
  left: 27px;
}

.mouth {
  position: absolute;
  height: 1px;
  width: 10px;
  background-color: #414141;
  border-radius: 2px;
  top: 20px;
  left: 15px;
  animation: emoji1-face-move 5s ease-in-out infinite;
  animation-direction: alternate;
}

@keyframes emoji1-eyes-blink {
  0% {
    height: 3px;
  }
  97% {
    height: 3px;
  }
  100% {
    height: 0px;
  }
}

@keyframes emoji1-face-move {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(0);
  }
  35% {
    transform: translateX(-5px);
  }
  65% {
    transform: translateX(-5px);
  }
  70% {
    transform: translateX(-3px);
  }
  85% {
    transform: translateX(-3px);
  }
  90% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
