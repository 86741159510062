
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .hide-sm {
    display: none !important;
  }
  .font-size-12-sm {
    font-size: 12px;
  }
  .text-align-rt {
    text-align: left;
  }


/*
  .ant-popover {
    top: 250px!important;
    left: 90px !important;
  } */

  .box {
    /* min-height: 65vh; */
    /*padding: 5vmax 0px;*/
    /*width: 90vw;*/
    width: 350px;
    min-height: 465px;
    padding-bottom: 20px;
  }



  .uni-list .name-container {
    width: 80%;
  }
  .uni-list .heart-container {
    width: 5vw;
  }

  .uni-list-content {
    min-width: 100px;
  }

  .logout-container {
    width: 320px !important;
  }

  .blue-input-width-lg {
    width: 130px;
  }
  .profile-streams-card {
    width: 100%;
  }

  .profile-streams-card-plus {
    width: 100%;
  }

  .numbers-card {
    margin-bottom: 20px;
  }
  .video-card {
    width: 100%;
    /*margin-bottom: 20px;*/
  }
  .img-card {
    width: 100%;
  }
  .img-card-plus {
    width: 100%;
  }
  .std-uni-card {
    width: 100%;
  }
  .general-card {
    width: 100%;
  }

  .profile-dropdown-img {
    position: absolute;
    left: 20px;
  }

  .slogan {
    font-size: 21px;
  }

  .column {
    display: flex;
    flex-direction: column;
  }
  .search-results-google {
    width: 90%;
  }
  .search-results {
    width: 60%;
  }

  .student-user {
    margin: 10px;
    min-width: 40%;
    height: 102px;
  }

  .border-right-divider {
    border: none;
  }
  .university-card {
    width: 100% !important;
  }

  #story-section {
    padding: 60px 20px 60px 20px !important;
  }
  #uniusers-section {
    padding: 60px 20px 60px 20px !important;
  }
  #ready-aboutus-section {
    padding: 60px 20px 60px 20px !important;
  }
  .story-card {
    padding: 15px !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .token-container {
    width: 360px !important;
  }
  .student-info-card {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sort-by-btn {
    font-size: 12px !important;
  }

  .team-profile-image {
    width: 120px;
    height: 120px;
  }

  .range-header-text {
    margin-right: 55vw;
  }
  .udentitylive-collapse-card {
    width: 300px;
  }

  .udentity-live-body {
    padding: 10px;
  }
  .ant-calendar-panel {
    width: 380px;
  }

  .features-paragraph {
    height: 600px;
  }

  .get-demo-btn {
    width: 100%;
    height: 56px;
  }

  .features-slideshow-container {
    height: 18vh;
  }
  .img-frame {
    margin-top: 20px;
  }

  .text-center-sm {
    text-align: center !important;
  }

  #ready-section {
    padding: 50px 0px;
  }
  .upcoming-event-card {
    /* height: 465px; */
    width: 330px;
  }

  .upcoming-event-image {
    height: 30%;
  }
  .features-image {
    max-height: 30vh;
  }

  .explore-school-item {
    height: auto;
  }

  .justify-center-sm {
    justify-content: center !important;
  }

  .justify-between-sm {
    justify-content: space-between !important;
  }


  .fair-list-item {
    display: flex;
    flex-direction: column;
  }

  .fair-dummy-box {
    height: 20px;
  }
  .filters-container {
    position: relative;
    background: #e8f1f8;
  }

  /* ZOOM SDK */
  .ReactModal__Content {
    width:350px!important;
  }
  .zm-modal-footer{
    display: flex;
    justify-content: center;;
  }
  /* END OF ZOOM SDK */
}
.event-card {
  width: 320px;
  height: 330px;
}

.event-card-explore {
  width: 320px;
  height: 330px;
}

.event-tag {
  padding: 3px 5px;
}

.btm-container {
  height: 180px;
}

.btm-container-explore{
  height:210px;
}

/* .event-card {
    height: 500px;
  } */

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .bs-contain {
    background-size: 100% !important;
  }
  #story-section {
    padding: 60px 20px 60px 20px !important;
  }
  #uniusers-section {
    padding: 60px 20px 60px 20px !important;
  }
  #ready-aboutus-section {
    padding: 60px 20px 60px 20px !important;
  }
  .story-card {
    padding: 15px !important;
  }
  .margin-ltsm-15 {
    margin-left: 15px;
  }

  .about-us-font {
    font-size: 18;
  }
  .login-section-height {
    min-height: 750px !important;
  }
  .ant-col-6 {
    width: 12.5% !important;
  }
  .ant-col-14 {
    width: 33.3% !important;
  }
  .kjIAYU .create-btn {
    float: left !important;
  }
  .font-sm-12 {
    font-size: 12px !important;
  }

  .font-sm-30 {
    font-size: 30px !important;
  }
  .padding-sm-10 {
    padding: 10px !important;
  }

  .ui-tooltip {
    right: 0 !important;
    left: 60% !important;
    margin-right: 20px;
    text-align: center;
    font-weight: bold;
  }
  .hs-settings-email-row {
    width: 100%;
    margin-left: 15px;
  }
  .margin-top-slogan {
    margin-top: 0px;
  }
  .our-vision-section-text {
    text-align: center;
    margin: auto;
  }
  .my-events-button-box {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
  .bs-contain {
    background-size: 100% !important;
  }
  .login-section-height {
    min-height: 960px !important;
  }
  .ant-col-6 {
    width: 12.5% !important;
  }
  .ant-col-14 {
    width: 33.3% !important;
  }
  .kjIAYU .create-btn {
    float: left !important;
  }
  .margin-ltsm-15 {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1300px) {
  .bs-contain {
    background-size: 80% !important;
  }
  .login-section-height {
    min-height: 650px !important;
  }
  .font-resp-30 {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .video-upload-container {
    width: 100%;
  }
  .login-section-height {
    min-height: 750px !important;
  }
  .res-v-height {
    height: 250px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .video-upload-container {
    width: 60%;
  }
}

@media only screen and (max-width: 320px) {
  .logout-container {
    width: 300px;
  }

  form.search input[type="search"] {
    width: 70% !important;
  }
  .search input[type="search"] {
    width: 70% !important;
  }
}

@media only screen and (min-width: 991px) {
  .uni-list .heart-container {
    width: 5vw;
  }

  .hide-md {
    display: none !important;
  }

  .margin-top-slogan {
    margin-top: 80px;
  }

  .search-results {
    margin-left: 10px;
  }
  .search-results-google {
    margin-left: 10px;
  }

  .box {
    min-height: 50vh;
    padding:30px 0px;
    width: 500px;
    /*height: 428px;*/
  }

  /* .box-button-font{
   font-size: 1.2vw
  } */


  .about-us-font {
    font-size: 24;
  }
  .ui-tooltip {
    font-weight: bold;
  }
  .hs-settings-email-row {
    width: 57%;
    margin-left: 15px;
  }
  .team-profile-image {
    width: 200px;
    height: 200px;
  }
  .person {
    padding: 100px 250px;
  }
  .udentitylive-collapse-card {
    width: 500px;
  }
  .student-user {
    margin: 10px;
    min-width: 105px;
    height: 102px;
  }
  .features-paragraph {
    height: 400px;
  }

  .get-demo-btn {
    width: 153px;
    height: 56px;
  }
  .features-slideshow-container {
    height: 45vh;
  }

  #ready-section {
    padding: 60px 100px 60px 100px;
  }

  .upcoming-event-card {
    height: 470px;
    width: 390px;
  }

  .upcoming-event-image {
    height: 30%;
  }
  .explore-school-item {
    height: 100px;
  }

  .fair-list-item {
    display: flex;
    flex-direction: row;
  }

  .btm-container {
    height: 205px;
  }

  .btm-container-explore{
    height: 205px;
  }

  .event-card {
    width: 100%;
    height: 400px;
    padding: 10px 5px;
  }

  .event-card-explore {
    width: 100%;
    height: 400px;
    padding: 10px 5px;
  }
  .event-tag {
    padding: 3px 5px;
  }
  .fair-dummy-box {
    height: 60px;
  }

  .fair-dummy-box {
    height: 60px;
  }

  .padding-btm-50-md{
    padding-bottom:50px;
  }
  .padding-lt-30-md{
    padding-left:30px
  }
  .padding-rt-30-md{
    padding-right:30px;
  }
}

@media only screen and (max-width: 1342px) {
  .video-upload-container {
    width: 100%;
  }
}
