@import url("https://fonts.googleapis.com/css?family=Montserrat");

html {
  height: 100%;
  /* overflow-x: hidden!important;  */
}

body {
  font-family: "Nunito Sans", "Noto Sans SC", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 100% !important;
  width: auto !important;
  /* overflow-x: hidden!important;  */
}

#app {
  height: 100%;
}

a:hover {
  opacity: 0.8;
}

.nunito-small {
  font-weight: 300;
}
.nunito-regular {
  font-weight: 400;
}
.nunito-medium {
  font-weight: 500;
}
.nunito-semibold {
  font-weight: 600;
}
.nunito-bold {
  font-weight: 700;
}
.nunito-extra-bold {
  font-weight: 900;
}
.nunito-italic {
  font-style: italic;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
}

.flex-row {
  display: flex;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.font-6 {
  font-size: 6px;
}
.font-7 {
  font-size: 7px;
}
.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-36 {
  font-size: 36px;
}
.font-38 {
  font-size: 38px;
}
.font-40 {
  font-size: 40px;
}
.font-44 {
  font-size: 44px;
}
.font-48 {
  font-size: 48px;
}

.font-70 {
  font-size: 70px;
}

.font-82 {
  font-size: 82px;
}

.font-96 {
  font-size: 96px;
}

.padding-0 {
  padding: 0px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-top-0 {
  padding-top: 0;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-80 {
  padding-top: 80px;
}
.padding-btm-5 {
  padding-bottom: 5px;
}
.padding-btm-10 {
  padding-bottom: 10px;
}
.padding-btm-20 {
  padding-bottom: 20px;
}
.padding-btm-40 {
  padding-bottom: 40px;
}
.padding-btm-50 {
  padding-bottom: 50px;
}
.padding-rt-5 {
  padding-right: 5px;
}
.padding-rt-15 {
  padding-right: 15px;
}
.padding-rt-20 {
  padding-right: 20px;
}
.padding-rt-25 {
  padding-right: 25px;
}
.padding-rt-30 {
  padding-right: 30px;
}
.padding-lt-0 {
  padding-left: 0;
}
.padding-lt-10 {
  padding-left: 10px;
}
.padding-lt-15 {
  padding-left: 15px;
}
.padding-lt-20 {
  padding-left: 20px;
}
.padding-lt-30 {
  padding-left: 30px;
}

.margin-0 {
  margin: 0px;
}
.margin-rt-5 {
  margin-right: 5px;
}
.margin-rt-10 {
  margin-right: 10px;
}
.margin-rt-15 {
  margin-right: 15px;
}
.margin-rt-20 {
  margin-right: 20px;
}
.margin-rt-25 {
  margin-right: 25px;
}
.margin-lt-5 {
  margin-left: 5px;
}
.margin-lt-10 {
  margin-left: 10px;
}
.margin-lt-15 {
  margin-left: 15px;
}
.margin-lt-20 {
  margin-left: 20px;
}
.margin-lt-30 {
  margin-left: 30px;
}

.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-130 {
  margin-top: 130px;
}
.margin-top-150 {
  margin-top: 150px;
}
.margin-top-170 {
  margin-top: 170px;
}
.margin-btm-5 {
  margin-bottom: 5px;
}
.margin-btm-10 {
  margin-bottom: 5px;
}
.margin-btm-20 {
  margin-bottom: 20px;
}

.text-align-rt {
  text-align: right;
}
.text-align-lt {
  text-align: left;
}
.text-align-ct {
  text-align: center;
}

.width-80-px {
  width: 80px;
}
.width-10 {
  width: 10%;
}
.width-12 {
  width: 12%;
}
.width-15 {
  width: 15%;
}
.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.width-80 {
  width: 80%;
}
.width-85 {
  width: 85%;
}
.width-90 {
  width: 90%;
}
.width-100 {
  width: 100%;
}

.height-15 {
  height: 15px;
}
.height-20 {
  height: 20px;
}
.height-30 {
  height: 30px;
}
.height-40 {
  height: 40px;
}
.height-50 {
  height: 50px;
}
.height-100 {
  height: 100px;
}
.height-130 {
  height: 130px;
}
.height-160 {
  height: 160px;
}

.border-btm-divider {
  border: 1px solid #bac6d0;
}
.border-right-divider {
  border-right: 1px solid #e5e5e5;
}

.clr-primary-blue {
  color: #1f6dd2 !important;
}

.clr-blue {
  color: #1771C7 !important;
}
.clr-dark-blue{
  color: #1D036B !important;
}
.clr-grey {
  color: #333333 !important;
}
.clr-light-grey {
  color: #757575 !important;
}
.clr-orange {
  color: #f6821f !important;
}
.clr-white {
  color: #ffffff !important;
}

.clr-interface-color{
  color: #F2F2F7;
}

.clr-black {
  color: #000000 !important;
}


.clr-yellow {
  color: #feb80c !important;
}
.clr-purple {
  color: #0f1c70 !important;
}
.clr-llight-grey {
  color: #d9d8da !important;
}
.clr-primary-blue {
  color: #1771c7 !important;
}

.background-white {
  background: #ffffff;
}
.background-grey {
  background: #e5e5e5;
}
.background-purple {
  background: #0f1c70;
}
.background-blue {
  background: #1771C7;
}

.background-black {
  background: #000000;
}

.border-blue {
  border: 1px solid #37919e;
}
.border-grey {
  border: 1px solid #bac6d0;
}
.border-purple {
  border: 1px solid #0f1c70;
}

.cursor {
  cursor: pointer;
}
.pos-r {
  position: relative;
}

.underline-text {
  border-bottom: 1px solid #1771c7;
}
.purple-underline-text {
  border-bottom: 1px solid #1771c7;
}
.underline-text-blue-2 {
  border-bottom: 2px solid #1771c7;
}
.orange-underline-text {
  border-bottom: 2px solid #f6821f;
}
.line {
  height: 2px;
  background-color: #1771c7;
}
.purple-line {
  height: 2px;
  background-color: #1771c7;
}


.letter-spacing-5 {
  letter-spacing: 5px;
}
.letter-spacing-02 {
  letter-spacing: 0.2em;
}
.letter-spacing-01 {
  letter-spacing: 0.1em;
}
.letter-spacing-05 {
  letter-spacing: 0.5em;
}
.letter-spacing-005 {
  letter-spacing: 0.05em;
}

.overflow-hidden {
  overflow: hidden;
}

.btn-dark-blue {
  background: #1771c7;
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-dark-blue:hover {
  opacity: 0.7;
}

.btn-dark-orange {
  background: #f6821f;
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-dark-orange:hover {
  opacity: 0.7;
}


.btn-blue:hover {
  opacity: 0.7;
}

.btn-lightgrey{
      background: #F2F2F7;
      border-radius: 14px;
      border-color: #F2F2F7;
      color: #1771C7;
      width: 130px;
      height: 54px;
      margin-left: 5px;

      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
}

.btn-dark-blue {
  background: #1771c7;
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-dark-blue:hover {
  opacity: 0.7;
}


.btn-light-blue {
  background: #c9e6eb;
  border-radius: 0px 4px 4px 0px;
  color: #37919e;
  transition: 0.3s;
}

.btn-light-blue:hover {
  opacity: 0.7;
}

.btn:hover {
  opacity: 0.7;
}

.btn-green {
  background: #25ae88;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
}

.btn-green:hover {
  opacity: 0.7;
}

.btn-orange {
  background: rgba(241, 132, 93, 0.4);
  border-radius: 3px;
  height: 50px;
  letter-spacing: 5px;
  transition: 0.3s;
}

.btn-orange:hover {
  opacity: 0.7;
}

.btn-orange-pure {
  background: #f6821f;
  border-radius: 14px;
  width: 130px;
  height: 54px;
  letter-spacing: 0.1px;
  transition: 0.3s;

  color: #FFFFFF;
  font-size: 17px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  padding: 5px 10px;
}

.btn-blue-pure{
  background: #1771C7;
    border-radius: 14px;
    border-color: #1771C7;
    color: white;
    width: 130px;
    height: 54px;
    margin-right: 5px;

    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
  letter-spacing: 0.1px;
}

.btn-white {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background: #F2F2F7;
  ;
  letter-spacing: 0.1px;
  height: 50px;
  transition: 0.3s;
  color: #1771C7;
  font-size: 15px;
}

.btn-orange-pure:hover {
  opacity: 0.7;
}

.btn-red {
  background: #dc4d1a;
  border-radius: 4px;
  width: auto;
  height: 20px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-red:hover {
  opacity: 0.7;
}

.btn-red-signup {
  background: #1771C7;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-red-signup:hover {
  opacity: 0.7;
}

.btn-purple-signup {
  background: #0f1c70;
  border-radius: 4px;
  width: 65px;
  height: 20px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-purple-signup:hover {
  opacity: 0.7;
}

.btn-yellow {
  background: #feb80c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #333333;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-yellow:hover {
  opacity: 0.7;
}

.btn-blue-active {
  background:#1771C7;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-purple-active {
  background: #1771C7;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-outline-red {
  background: #ffffff;
  border: 1px solid #f6821f;
  color: #f6821f;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}

.btn-outline-red:hover {
  opacity: 0.7;
}

.btn-outline-orange {
  background: #ffffff;
  border: 1px solid #dc4d1a;
  width: 220px;
  color: #dc4d1a;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}

.btn-outline-orange:hover {
  opacity: 0.7;
}

.btn-outline-blue {
  background: white;
  border-radius: 14px;
  padding: 5px 10px;
  color: #1771C7;
  width: 130px;
  height: 54px;
  border-color: #1771C7;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  width: 130px;
  height: 54px;

}

.btn-outline-blue:hover {
  opacity: 0.7;
}

.btn-outline-dark-blue {
  background: #ffffff;
  border: 1px solid #1771c7;
  color: #1771c7;
  box-sizing: border-box;
  border-radius: 14px;
  transition: 0.3s;
}

.btn-outline-dark-blue:hover {
  opacity: 0.7;
}

.btn-outline-purple {
  background: #ffffff;
  border: 1px solid #1771c7;
  color: #1771c7;
  box-sizing: border-box;
  border-radius: 3px;
  transition: 0.3s;
}

.btn-outline-purple:hover {
  opacity: 0.7;
}

.btn-outline-green {
  background: #ffffff;
  border: 1px solid #25ae88;
  color: #25ae88;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}
.btn-outline-green:hover {
  opacity: 0.7;
}

.btn-outline-light-blue {
  background: #ffffff;
  border: 1px solid #4cadbb;
  color: #4cadbb;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}
.btn-outline-light-blue:hover {
  opacity: 0.7;
}

.btn-active-blue {
  border: 1px solid #37919e;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #37919e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  transition: 0.3s;
}

.btn-active-purple {
  border: 1px solid #1771C7;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #1771C7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  transition: 0.3s;
  background-color: #5BA6EC;
}

.btn-purple {
  background: #0f1c70;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}



.btn-purple:hover {
  opacity: 0.7;
}

/* .btn-outline-purple {
  border: 1px solid #0f1c70 !important;
  box-sizing: border-box;
  border-radius: 4px;
} */

.btn-grey {
  background: #cfd2e2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-grey:hover {
  opacity: 0.7;
}

.custom-form-control {
  display: block;
  background: #ffffff;
  border: 1px solid #bac6d0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px !important;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  color: #333333;
  opacity: 0.5;
  transition: 0.3s;
}

.blue-border-input {
  background: #ffffff;
  border: 1px solid #1771C7;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.3s;
}

.purple-border-input {
  background: #ffffff;
  border: 1px solid #1771C7;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.3s;
}

textarea {
  resize: none;
}

.blue-input-width-sm {
  width: 98px;
}

.blue-input-width-lg {
  width: 98px;
}

.input-bg-arrow {
  background: url(../img/chevron-sign-down-blue.svg) no-repeat right 5px center;
}

.text-dec-none {
  text-decoration: none !important;
}

.display-none {
  display: none;
}

.country-dropdown {
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
}

.country-dropdown.active {
  background: #0f1c70;
  color: #ffffff;
}

.country-dropdown.inactive {
  background: #ffffff;
  border: 1px solid #0f1c70;
  color: #37919e;
}

.country-dropdown-menu {
  position: absolute;
  top: 90%;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 0px;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid #0f1c70;
}

.country-dropdown li {
  height: 35px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0px;
  color: #37919e;
  border-bottom: 1px solid #37919e;
}

.black-placeholder::placeholder {
  color: #333333;
}

.error-text {
  color: #ed1414 !important;
}

.float-right {
  float: right;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.purple-circle-40 {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #1771C7;
}

.hs-progress {
  height: 8px !important;
  /* background: #0F1C70 */
}

.hs-progress .blue-bar {
  background-color:#1771C7 !important;
}

.tab-orange {
  background: #dc4d1a;
  border-radius: 0;
  color: #ffffff;
  border: solid 1px #dc4d1a;
  transition: 0.3s;
}

.tab-outline-orange {
  background: #ffffff;
  border: solid 1px #efb19b;
  border-left-width: 0;
  border-radius: 0;
  color: #efb19b;
}

.tab-outline-orange:first-child {
  border-left-width: 1px;
}

/* Marker animation */

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #1771c7;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.pin::after {
  content: "";
  width: 14px;
  height: 14px;
  top: 10px;
  left: 7px;
  background: #2f2f2f;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse::after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -15px 0 0 -20px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #89849b;
  animation-delay: 1.1s;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Modifying react player shadow class */
.react-player__shadow {
  background: radial-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0) 500%
  ) !important;
}

.react-player__shadow:hover {
  opacity: 0.8;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

.large-button {
  height: 70px;
}

.stop-scrolling {
  overflow: hidden;
}

/* Logout box */
.logout-box {
  margin-top: 30vh;
}

.image-responsive {
  height: auto;
  width: 100%;
}

.image-box {
  transition: 0.3s;
}

.image-box:hover {
  opacity: 0.7;
}

.profile-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 10px 0;
}

/* Modifications for antd dropdown */
.ant-select-selection--single {
  height: 100% !important;
}

.ant-select-selection__rendered {
  height: 100% !important;
}
.ant-select-selection-selected-value {
  display: flex !important;
  align-items: center;
  height: 100% !important;
}

.event-tag {
  /* font-16 px-1 mr-1 mb-1 cursor */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height:35px;
  width:90px; */
  width: 90px;
  font-size: 14px;
  margin: 5px 5px 0px 0px;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.event-tag-university {
  /* font-16 px-1 mr-1 mb-1 cursor */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 13px;
  padding: 8px;
  margin: 5px 1px 0px 0px;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.event-tag-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 16px;
  padding: 0px 10px;
  margin: 0px 15px 15px 0px;
  cursor: pointer;
  font-weight: 550;
  transition: all 0.3s ease-in-out;
  border: 1.4px solid #4cadbb;
  border-radius: 5px;
  cursor: default;
}

.dropdown-menu li:hover {
  background-color: #1771C7 !important;
}

.ant-message .anticon {
  top: -3px !important;
}

.ant-popover-inner-content {
  text-align: center;
}

.ant-popover-message-title {
  padding: 10px !important;
}

.ant-popover-inner-content .ant-btn {
  color: #1771C7;
  border: 1px solid #1771C7;
}

.ant-popover-inner-content .ant-btn-primary {
  color: #fff;
  background: #1771C7;
}

.flag-confirm .ant-btn {
  color: #1771C7;
  border: 1px solid #1771C7;
}

.flag-confirm .ant-btn-primary {
  color: #fff;
  background: #1771C7;
}

.cancel-confirm .ant-btn {
  width: 157px;
  height: 50px;
  text-align: center;
  color: #f6821f;
  border: 1px solid #f6821f;
}

.cancel-confirm .ant-btn-primary {
  color: #fff;
  background: #f6821f;
}

.cancel-confirm .ant-popover-buttons {
  text-align: center;
}

.cancel-confirm .ant-popover-inner-content {
  padding: 30px;
}

.cancel-confirm .enhance {
  font-weight: bolder;
}

.opento-popover .ant-popover-inner-content {
  width: 200px;
  display: flex;
  font-size: 15px;
  border-radius:12px;
  text-align: center;
  color: #000;
  background: #BDBDBD;
}

.ant-popover-placement-top.opento-popover>.ant-popover-content>.ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.8) !important;
  border-right-color: rgba(0, 0, 0, 0.8) !important;
}

body {
  overflow: auto !important;
}


/* Links for header and footer */

/* .nav-link {
  z-index:10;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
  margin: 0 10px;
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
  position: relative;
  opacity: 1;
}


.nav-link:hover {
  cursor: pointer;
  color: #0f1c70!important;
}


.nav-link::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: #0f1c70;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 0px;
}

.nav-link-ltr:hover::before {
  width:100%;
  transition: 300ms;
} */
