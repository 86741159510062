@import url(https://fonts.googleapis.com/css?family=Montserrat);
.big-title {
    font-size: 36px !important;
    line-height: 55px !important;
    font-weight: 700 !important;
}

.second-title {
    font-size: 22px !important;
    line-height: 40px !important;
    font-weight: 700 !important;
}

.second-text {
    font-size: 22px !important;
    line-height: 40px !important;
    font-weight: 400 !important;
}

.normal-text {
    font-size: 15px !important;
    line-height: 25px !important;
    font-weight: 400 !important;
}

.btn-text {
    font-size: 15px !important;
    font-weight: 700 !important;
}

body {
    font-family: "Nunito Sans" !important;
}

.ant-message {
    z-index: 10000001;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 50vh;
  width: 30vw; */
  font-family: inherit;
  background: #fff;
  border-radius: 10px;
  /*-webkit-box-shadow: 10px 10px 39px 0px rgba(0, 0, 0, 0.65);*/
  /*-moz-box-shadow: 10px 10px 39px 0px rgba(0, 0, 0, 0.65);*/
  /*box-shadow: 10px 10px 39px 0px rgba(0, 0, 0, 0.65);*/
}
.box h1 {
  text-align: center;
  margin: 30px 0;
  font-size: 30px;
}
.box input {
  display: block;
  margin: 20px auto;
  padding: 15px;
  background: rgba(0, 0, 0, 0.2);
  color: #000;
  border: 0;
}
.box input:focus,
.box input:active,
.box button:focus,
.box button:active {
  outline: none;
}
.box button {
  background: #1771C7;
  border: 0;
  color: rgb(255, 255, 255);
  padding: 10px;
  /* font-size: 20px; */
  margin: 20px auto;
  display: block;
  cursor: pointer;
}
.box button:active {
  background: rgb(116, 121, 155);
}

.box button:hover {
  background: rgb(116, 121, 155);
}
.box p {
  font-size: 14px;
  text-align: center;
}
.box p span {
  cursor: pointer;
  color: #666;
}

.signup,
.login {
  -webkit-animation: ease-in-out;
          animation: ease-in-out;
  font-weight: bold;
  color: #0f1c70;
}

.signup:hover,
.login:hover {
  cursor: pointer;
  font-weight: bold;
  opacity: 0.7;
}

.reset-password:hover {
  opacity: 0.7;
}

.modal-panel {
  display: flex;
}

.right-panel {
  margin-top: 100px;
  margin-bottom: 90px;
  padding-left: 50px;
  padding-right: 40px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 500px;
  padding-top: 20px;
}

.change-icons-title {
  color: #757575;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.change-icons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px 10px;
  margin-bottom: -10px;
}

.change-icons > div {
    width: 175px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #1771C7;
    background: #FFF;
    color: #757575;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    margin-bottom: 0;

}

.right-panel-title {
  color: #757575;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.right-panel-text {
  color: #757575;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 15px auto;
}

.right-panel-text > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.mobile-new {
  display: none;
}

.mobile-icon-img {
  display: none;
}

.logo-title {
  text-align: center;
  margin-bottom: 30px;
  padding-top: 30px;
}

.middle-line {
  width: 1px;
  height: 214.056px;
  background: #757575;
  align-self: center;
  margin-top: 30px;
}



@media (max-width: 991px) {

  .mobile-icon-img {
    display: block;
    text-align: center;
  }

  .mobile-icon-img > img {
    width: 160px;
    height: 35px;
  }

  .right-panel {
    width: 350px;
    height: 465px;
    margin: 0;
    padding-top: 28px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .right-panel-title {
    display: none;
  }

  .right-panel-text1 {
    display: none;
  }

  .change-icons {
    grid-template-columns: 1fr;
  }

  .change-icons > div {
    width: 100%;
  }

  .mobile-new {
    display: block;
    margin-top: 45px;
  }

  .mobile-new > div:nth-child(1) {
    color: #F6821F;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .mobile-new > div:nth-child(2) {
    color: #1771C7;
    cursor: pointer;
    text-decoration: underline;
  }

  .login-with-phone {
    color: #757575;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    width: 100%;
    margin: 5px auto;
  }
}



.sign-btn {
  background: #F2F2F7;
  border-radius: 14px !important;
  width: 119px;
  height: 54px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: center !important;
  letter-spacing: 0.1px;
  color: #1771C7;
}

.login-btn {
  background: #F2F2F7;
  border-radius: 14px !important;
  width: 199.16px;
  height: 54px;
  flex-shrink: 0;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: center !important;
  letter-spacing: 0.1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1771C7;
}

.logo-text {
  color: #1771C7;
  font-weight: bold;
  font-size: 35px;
}

.nav-content {
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  justify-content: center;
}

.nav-content > a {
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  position: relative;
  align-self: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.nav-content > a::after {
   height: 5px;
   content: "";
   position: absolute;
   background-color: rgb(23, 113, 199);
   width: 0;
   left: 0;
   bottom: -5px;
   transition: width 0.5s;
 }

.nav-content > a.selected::after , .nav-content > a:hover::after  {
   width: 100%;
}
@media (min-width: 320px) and (max-width: 991px) {
  .logo-text {
    display: none;
  }
  .sign-btn, .login-btn {
    background-color: white;
    color: black;
  }
  .translate-mark {
    display: none;
  }

}

.emojis-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
}

.moving-emoji {
  border-radius: 50%;
  position: relative;
  width: 32px;
  height: 32px;
  background-color: #ffeb96;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 5px -2px;
  cursor: pointer;
}

.emoji1::after,
.emoji1::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 3px;
  background-color: #414141;
  border-radius: 50%;
  top: 15px;
  -webkit-animation: emoji1-face-move 5s ease-in-out infinite,
    emoji1-eyes-blink 2s ease-in-out infinite;
          animation: emoji1-face-move 5s ease-in-out infinite,
    emoji1-eyes-blink 2s ease-in-out infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.emoji1::after {
  left: 10px;
}

.emoji1::before {
  left: 27px;
}

.mouth {
  position: absolute;
  height: 1px;
  width: 10px;
  background-color: #414141;
  border-radius: 2px;
  top: 20px;
  left: 15px;
  -webkit-animation: emoji1-face-move 5s ease-in-out infinite;
          animation: emoji1-face-move 5s ease-in-out infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

@-webkit-keyframes emoji1-eyes-blink {
  0% {
    height: 3px;
  }
  97% {
    height: 3px;
  }
  100% {
    height: 0px;
  }
}

@keyframes emoji1-eyes-blink {
  0% {
    height: 3px;
  }
  97% {
    height: 3px;
  }
  100% {
    height: 0px;
  }
}

@-webkit-keyframes emoji1-face-move {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  35% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  65% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  70% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  85% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes emoji1-face-move {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  30% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  35% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  65% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  70% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  85% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

html {
  height: 100%;
  /* overflow-x: hidden!important;  */
}

body {
  font-family: "Nunito Sans", "Noto Sans SC", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 100% !important;
  width: auto !important;
  /* overflow-x: hidden!important;  */
}

#app {
  height: 100%;
}

a:hover {
  opacity: 0.8;
}

.nunito-small {
  font-weight: 300;
}
.nunito-regular {
  font-weight: 400;
}
.nunito-medium {
  font-weight: 500;
}
.nunito-semibold {
  font-weight: 600;
}
.nunito-bold {
  font-weight: 700;
}
.nunito-extra-bold {
  font-weight: 900;
}
.nunito-italic {
  font-style: italic;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
}

.flex-row {
  display: flex;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.font-6 {
  font-size: 6px;
}
.font-7 {
  font-size: 7px;
}
.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-36 {
  font-size: 36px;
}
.font-38 {
  font-size: 38px;
}
.font-40 {
  font-size: 40px;
}
.font-44 {
  font-size: 44px;
}
.font-48 {
  font-size: 48px;
}

.font-70 {
  font-size: 70px;
}

.font-82 {
  font-size: 82px;
}

.font-96 {
  font-size: 96px;
}

.padding-0 {
  padding: 0px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-top-0 {
  padding-top: 0;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-80 {
  padding-top: 80px;
}
.padding-btm-5 {
  padding-bottom: 5px;
}
.padding-btm-10 {
  padding-bottom: 10px;
}
.padding-btm-20 {
  padding-bottom: 20px;
}
.padding-btm-40 {
  padding-bottom: 40px;
}
.padding-btm-50 {
  padding-bottom: 50px;
}
.padding-rt-5 {
  padding-right: 5px;
}
.padding-rt-15 {
  padding-right: 15px;
}
.padding-rt-20 {
  padding-right: 20px;
}
.padding-rt-25 {
  padding-right: 25px;
}
.padding-rt-30 {
  padding-right: 30px;
}
.padding-lt-0 {
  padding-left: 0;
}
.padding-lt-10 {
  padding-left: 10px;
}
.padding-lt-15 {
  padding-left: 15px;
}
.padding-lt-20 {
  padding-left: 20px;
}
.padding-lt-30 {
  padding-left: 30px;
}

.margin-0 {
  margin: 0px;
}
.margin-rt-5 {
  margin-right: 5px;
}
.margin-rt-10 {
  margin-right: 10px;
}
.margin-rt-15 {
  margin-right: 15px;
}
.margin-rt-20 {
  margin-right: 20px;
}
.margin-rt-25 {
  margin-right: 25px;
}
.margin-lt-5 {
  margin-left: 5px;
}
.margin-lt-10 {
  margin-left: 10px;
}
.margin-lt-15 {
  margin-left: 15px;
}
.margin-lt-20 {
  margin-left: 20px;
}
.margin-lt-30 {
  margin-left: 30px;
}

.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-130 {
  margin-top: 130px;
}
.margin-top-150 {
  margin-top: 150px;
}
.margin-top-170 {
  margin-top: 170px;
}
.margin-btm-5 {
  margin-bottom: 5px;
}
.margin-btm-10 {
  margin-bottom: 5px;
}
.margin-btm-20 {
  margin-bottom: 20px;
}

.text-align-rt {
  text-align: right;
}
.text-align-lt {
  text-align: left;
}
.text-align-ct {
  text-align: center;
}

.width-80-px {
  width: 80px;
}
.width-10 {
  width: 10%;
}
.width-12 {
  width: 12%;
}
.width-15 {
  width: 15%;
}
.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.width-80 {
  width: 80%;
}
.width-85 {
  width: 85%;
}
.width-90 {
  width: 90%;
}
.width-100 {
  width: 100%;
}

.height-15 {
  height: 15px;
}
.height-20 {
  height: 20px;
}
.height-30 {
  height: 30px;
}
.height-40 {
  height: 40px;
}
.height-50 {
  height: 50px;
}
.height-100 {
  height: 100px;
}
.height-130 {
  height: 130px;
}
.height-160 {
  height: 160px;
}

.border-btm-divider {
  border: 1px solid #bac6d0;
}
.border-right-divider {
  border-right: 1px solid #e5e5e5;
}

.clr-primary-blue {
  color: #1f6dd2 !important;
}

.clr-blue {
  color: #1771C7 !important;
}
.clr-dark-blue{
  color: #1D036B !important;
}
.clr-grey {
  color: #333333 !important;
}
.clr-light-grey {
  color: #757575 !important;
}
.clr-orange {
  color: #f6821f !important;
}
.clr-white {
  color: #ffffff !important;
}

.clr-interface-color{
  color: #F2F2F7;
}

.clr-black {
  color: #000000 !important;
}


.clr-yellow {
  color: #feb80c !important;
}
.clr-purple {
  color: #0f1c70 !important;
}
.clr-llight-grey {
  color: #d9d8da !important;
}
.clr-primary-blue {
  color: #1771c7 !important;
}

.background-white {
  background: #ffffff;
}
.background-grey {
  background: #e5e5e5;
}
.background-purple {
  background: #0f1c70;
}
.background-blue {
  background: #1771C7;
}

.background-black {
  background: #000000;
}

.border-blue {
  border: 1px solid #37919e;
}
.border-grey {
  border: 1px solid #bac6d0;
}
.border-purple {
  border: 1px solid #0f1c70;
}

.cursor {
  cursor: pointer;
}
.pos-r {
  position: relative;
}

.underline-text {
  border-bottom: 1px solid #1771c7;
}
.purple-underline-text {
  border-bottom: 1px solid #1771c7;
}
.underline-text-blue-2 {
  border-bottom: 2px solid #1771c7;
}
.orange-underline-text {
  border-bottom: 2px solid #f6821f;
}
.line {
  height: 2px;
  background-color: #1771c7;
}
.purple-line {
  height: 2px;
  background-color: #1771c7;
}


.letter-spacing-5 {
  letter-spacing: 5px;
}
.letter-spacing-02 {
  letter-spacing: 0.2em;
}
.letter-spacing-01 {
  letter-spacing: 0.1em;
}
.letter-spacing-05 {
  letter-spacing: 0.5em;
}
.letter-spacing-005 {
  letter-spacing: 0.05em;
}

.overflow-hidden {
  overflow: hidden;
}

.btn-dark-blue {
  background: #1771c7;
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-dark-blue:hover {
  opacity: 0.7;
}

.btn-dark-orange {
  background: #f6821f;
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-dark-orange:hover {
  opacity: 0.7;
}


.btn-blue:hover {
  opacity: 0.7;
}

.btn-lightgrey{
      background: #F2F2F7;
      border-radius: 14px;
      border-color: #F2F2F7;
      color: #1771C7;
      width: 130px;
      height: 54px;
      margin-left: 5px;

      font-family: Nunito Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
}

.btn-dark-blue {
  background: #1771c7;
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-dark-blue:hover {
  opacity: 0.7;
}


.btn-light-blue {
  background: #c9e6eb;
  border-radius: 0px 4px 4px 0px;
  color: #37919e;
  transition: 0.3s;
}

.btn-light-blue:hover {
  opacity: 0.7;
}

.btn:hover {
  opacity: 0.7;
}

.btn-green {
  background: #25ae88;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s;
}

.btn-green:hover {
  opacity: 0.7;
}

.btn-orange {
  background: rgba(241, 132, 93, 0.4);
  border-radius: 3px;
  height: 50px;
  letter-spacing: 5px;
  transition: 0.3s;
}

.btn-orange:hover {
  opacity: 0.7;
}

.btn-orange-pure {
  background: #f6821f;
  border-radius: 14px;
  width: 130px;
  height: 54px;
  letter-spacing: 0.1px;
  transition: 0.3s;

  color: #FFFFFF;
  font-size: 17px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  padding: 5px 10px;
}

.btn-blue-pure{
  background: #1771C7;
    border-radius: 14px;
    border-color: #1771C7;
    color: white;
    width: 130px;
    height: 54px;
    margin-right: 5px;

    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
  letter-spacing: 0.1px;
}

.btn-white {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background: #F2F2F7;
  ;
  letter-spacing: 0.1px;
  height: 50px;
  transition: 0.3s;
  color: #1771C7;
  font-size: 15px;
}

.btn-orange-pure:hover {
  opacity: 0.7;
}

.btn-red {
  background: #dc4d1a;
  border-radius: 4px;
  width: auto;
  height: 20px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-red:hover {
  opacity: 0.7;
}

.btn-red-signup {
  background: #1771C7;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-red-signup:hover {
  opacity: 0.7;
}

.btn-purple-signup {
  background: #0f1c70;
  border-radius: 4px;
  width: 65px;
  height: 20px;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-purple-signup:hover {
  opacity: 0.7;
}

.btn-yellow {
  background: #feb80c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #333333;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-yellow:hover {
  opacity: 0.7;
}

.btn-blue-active {
  background:#1771C7;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-purple-active {
  background: #1771C7;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.btn-outline-red {
  background: #ffffff;
  border: 1px solid #f6821f;
  color: #f6821f;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}

.btn-outline-red:hover {
  opacity: 0.7;
}

.btn-outline-orange {
  background: #ffffff;
  border: 1px solid #dc4d1a;
  width: 220px;
  color: #dc4d1a;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}

.btn-outline-orange:hover {
  opacity: 0.7;
}

.btn-outline-blue {
  background: white;
  border-radius: 14px;
  padding: 5px 10px;
  color: #1771C7;
  width: 130px;
  height: 54px;
  border-color: #1771C7;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  width: 130px;
  height: 54px;

}

.btn-outline-blue:hover {
  opacity: 0.7;
}

.btn-outline-dark-blue {
  background: #ffffff;
  border: 1px solid #1771c7;
  color: #1771c7;
  box-sizing: border-box;
  border-radius: 14px;
  transition: 0.3s;
}

.btn-outline-dark-blue:hover {
  opacity: 0.7;
}

.btn-outline-purple {
  background: #ffffff;
  border: 1px solid #1771c7;
  color: #1771c7;
  box-sizing: border-box;
  border-radius: 3px;
  transition: 0.3s;
}

.btn-outline-purple:hover {
  opacity: 0.7;
}

.btn-outline-green {
  background: #ffffff;
  border: 1px solid #25ae88;
  color: #25ae88;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}
.btn-outline-green:hover {
  opacity: 0.7;
}

.btn-outline-light-blue {
  background: #ffffff;
  border: 1px solid #4cadbb;
  color: #4cadbb;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.3s;
}
.btn-outline-light-blue:hover {
  opacity: 0.7;
}

.btn-active-blue {
  border: 1px solid #37919e;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #37919e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  transition: 0.3s;
}

.btn-active-purple {
  border: 1px solid #1771C7;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #1771C7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  transition: 0.3s;
  background-color: #5BA6EC;
}

.btn-purple {
  background: #0f1c70;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}



.btn-purple:hover {
  opacity: 0.7;
}

/* .btn-outline-purple {
  border: 1px solid #0f1c70 !important;
  box-sizing: border-box;
  border-radius: 4px;
} */

.btn-grey {
  background: #cfd2e2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-grey:hover {
  opacity: 0.7;
}

.custom-form-control {
  display: block;
  background: #ffffff;
  border: 1px solid #bac6d0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px !important;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  color: #333333;
  opacity: 0.5;
  transition: 0.3s;
}

.blue-border-input {
  background: #ffffff;
  border: 1px solid #1771C7;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.3s;
}

.purple-border-input {
  background: #ffffff;
  border: 1px solid #1771C7;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.3s;
}

textarea {
  resize: none;
}

.blue-input-width-sm {
  width: 98px;
}

.blue-input-width-lg {
  width: 98px;
}

.input-bg-arrow {
  background: url(/static/media/chevron-sign-down-blue.4a1896db.svg) no-repeat right 5px center;
}

.text-dec-none {
  text-decoration: none !important;
}

.display-none {
  display: none;
}

.country-dropdown {
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
}

.country-dropdown.active {
  background: #0f1c70;
  color: #ffffff;
}

.country-dropdown.inactive {
  background: #ffffff;
  border: 1px solid #0f1c70;
  color: #37919e;
}

.country-dropdown-menu {
  position: absolute;
  top: 90%;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 0px;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid #0f1c70;
}

.country-dropdown li {
  height: 35px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0px;
  color: #37919e;
  border-bottom: 1px solid #37919e;
}

.black-placeholder::-webkit-input-placeholder {
  color: #333333;
}

.black-placeholder::placeholder {
  color: #333333;
}

.error-text {
  color: #ed1414 !important;
}

.float-right {
  float: right;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.purple-circle-40 {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #1771C7;
}

.hs-progress {
  height: 8px !important;
  /* background: #0F1C70 */
}

.hs-progress .blue-bar {
  background-color:#1771C7 !important;
}

.tab-orange {
  background: #dc4d1a;
  border-radius: 0;
  color: #ffffff;
  border: solid 1px #dc4d1a;
  transition: 0.3s;
}

.tab-outline-orange {
  background: #ffffff;
  border: solid 1px #efb19b;
  border-left-width: 0;
  border-radius: 0;
  color: #efb19b;
}

.tab-outline-orange:first-child {
  border-left-width: 1px;
}

/* Marker animation */

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #1771c7;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.pin::after {
  content: "";
  width: 14px;
  height: 14px;
  top: 10px;
  left: 7px;
  background: #2f2f2f;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
          transform: rotateX(55deg);
  z-index: -2;
}
.pulse::after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -15px 0 0 -20px;
  -webkit-animation: pulsate 1s ease-out;
          animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #89849b;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Modifying react player shadow class */
.react-player__shadow {
  background: radial-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0) 500%
  ) !important;
}

.react-player__shadow:hover {
  opacity: 0.8;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

.large-button {
  height: 70px;
}

.stop-scrolling {
  overflow: hidden;
}

/* Logout box */
.logout-box {
  margin-top: 30vh;
}

.image-responsive {
  height: auto;
  width: 100%;
}

.image-box {
  transition: 0.3s;
}

.image-box:hover {
  opacity: 0.7;
}

.profile-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 10px 0;
}

/* Modifications for antd dropdown */
.ant-select-selection--single {
  height: 100% !important;
}

.ant-select-selection__rendered {
  height: 100% !important;
}
.ant-select-selection-selected-value {
  display: flex !important;
  align-items: center;
  height: 100% !important;
}

.event-tag {
  /* font-16 px-1 mr-1 mb-1 cursor */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height:35px;
  width:90px; */
  width: 90px;
  font-size: 14px;
  margin: 5px 5px 0px 0px;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.event-tag-university {
  /* font-16 px-1 mr-1 mb-1 cursor */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 13px;
  padding: 8px;
  margin: 5px 1px 0px 0px;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.event-tag-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 16px;
  padding: 0px 10px;
  margin: 0px 15px 15px 0px;
  cursor: pointer;
  font-weight: 550;
  transition: all 0.3s ease-in-out;
  border: 1.4px solid #4cadbb;
  border-radius: 5px;
  cursor: default;
}

.dropdown-menu li:hover {
  background-color: #1771C7 !important;
}

.ant-message .anticon {
  top: -3px !important;
}

.ant-popover-inner-content {
  text-align: center;
}

.ant-popover-message-title {
  padding: 10px !important;
}

.ant-popover-inner-content .ant-btn {
  color: #1771C7;
  border: 1px solid #1771C7;
}

.ant-popover-inner-content .ant-btn-primary {
  color: #fff;
  background: #1771C7;
}

.flag-confirm .ant-btn {
  color: #1771C7;
  border: 1px solid #1771C7;
}

.flag-confirm .ant-btn-primary {
  color: #fff;
  background: #1771C7;
}

.cancel-confirm .ant-btn {
  width: 157px;
  height: 50px;
  text-align: center;
  color: #f6821f;
  border: 1px solid #f6821f;
}

.cancel-confirm .ant-btn-primary {
  color: #fff;
  background: #f6821f;
}

.cancel-confirm .ant-popover-buttons {
  text-align: center;
}

.cancel-confirm .ant-popover-inner-content {
  padding: 30px;
}

.cancel-confirm .enhance {
  font-weight: bolder;
}

.opento-popover .ant-popover-inner-content {
  width: 200px;
  display: flex;
  font-size: 15px;
  border-radius:12px;
  text-align: center;
  color: #000;
  background: #BDBDBD;
}

.ant-popover-placement-top.opento-popover>.ant-popover-content>.ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.8) !important;
  border-right-color: rgba(0, 0, 0, 0.8) !important;
}

body {
  overflow: auto !important;
}


/* Links for header and footer */

/* .nav-link {
  z-index:10;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
  margin: 0 10px;
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
  position: relative;
  opacity: 1;
}


.nav-link:hover {
  cursor: pointer;
  color: #0f1c70!important;
}


.nav-link::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: #0f1c70;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 0px;
}

.nav-link-ltr:hover::before {
  width:100%;
  transition: 300ms;
} */


@media only screen and (min-width: 320px) and (max-width: 991px) {
  .hide-sm {
    display: none !important;
  }
  .font-size-12-sm {
    font-size: 12px;
  }
  .text-align-rt {
    text-align: left;
  }


/*
  .ant-popover {
    top: 250px!important;
    left: 90px !important;
  } */

  .box {
    /* min-height: 65vh; */
    /*padding: 5vmax 0px;*/
    /*width: 90vw;*/
    width: 350px;
    min-height: 465px;
    padding-bottom: 20px;
  }



  .uni-list .name-container {
    width: 80%;
  }
  .uni-list .heart-container {
    width: 5vw;
  }

  .uni-list-content {
    min-width: 100px;
  }

  .logout-container {
    width: 320px !important;
  }

  .blue-input-width-lg {
    width: 130px;
  }
  .profile-streams-card {
    width: 100%;
  }

  .profile-streams-card-plus {
    width: 100%;
  }

  .numbers-card {
    margin-bottom: 20px;
  }
  .video-card {
    width: 100%;
    /*margin-bottom: 20px;*/
  }
  .img-card {
    width: 100%;
  }
  .img-card-plus {
    width: 100%;
  }
  .std-uni-card {
    width: 100%;
  }
  .general-card {
    width: 100%;
  }

  .profile-dropdown-img {
    position: absolute;
    left: 20px;
  }

  .slogan {
    font-size: 21px;
  }

  .column {
    display: flex;
    flex-direction: column;
  }
  .search-results-google {
    width: 90%;
  }
  .search-results {
    width: 60%;
  }

  .student-user {
    margin: 10px;
    min-width: 40%;
    height: 102px;
  }

  .border-right-divider {
    border: none;
  }
  .university-card {
    width: 100% !important;
  }

  #story-section {
    padding: 60px 20px 60px 20px !important;
  }
  #uniusers-section {
    padding: 60px 20px 60px 20px !important;
  }
  #ready-aboutus-section {
    padding: 60px 20px 60px 20px !important;
  }
  .story-card {
    padding: 15px !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .token-container {
    width: 360px !important;
  }
  .student-info-card {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sort-by-btn {
    font-size: 12px !important;
  }

  .team-profile-image {
    width: 120px;
    height: 120px;
  }

  .range-header-text {
    margin-right: 55vw;
  }
  .udentitylive-collapse-card {
    width: 300px;
  }

  .udentity-live-body {
    padding: 10px;
  }
  .ant-calendar-panel {
    width: 380px;
  }

  .features-paragraph {
    height: 600px;
  }

  .get-demo-btn {
    width: 100%;
    height: 56px;
  }

  .features-slideshow-container {
    height: 18vh;
  }
  .img-frame {
    margin-top: 20px;
  }

  .text-center-sm {
    text-align: center !important;
  }

  #ready-section {
    padding: 50px 0px;
  }
  .upcoming-event-card {
    /* height: 465px; */
    width: 330px;
  }

  .upcoming-event-image {
    height: 30%;
  }
  .features-image {
    max-height: 30vh;
  }

  .explore-school-item {
    height: auto;
  }

  .justify-center-sm {
    justify-content: center !important;
  }

  .justify-between-sm {
    justify-content: space-between !important;
  }


  .fair-list-item {
    display: flex;
    flex-direction: column;
  }

  .fair-dummy-box {
    height: 20px;
  }
  .filters-container {
    position: relative;
    background: #e8f1f8;
  }

  /* ZOOM SDK */
  .ReactModal__Content {
    width:350px!important;
  }
  .zm-modal-footer{
    display: flex;
    justify-content: center;;
  }
  /* END OF ZOOM SDK */
}
.event-card {
  width: 320px;
  height: 330px;
}

.event-card-explore {
  width: 320px;
  height: 330px;
}

.event-tag {
  padding: 3px 5px;
}

.btm-container {
  height: 180px;
}

.btm-container-explore{
  height:210px;
}

/* .event-card {
    height: 500px;
  } */

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .bs-contain {
    background-size: 100% !important;
  }
  #story-section {
    padding: 60px 20px 60px 20px !important;
  }
  #uniusers-section {
    padding: 60px 20px 60px 20px !important;
  }
  #ready-aboutus-section {
    padding: 60px 20px 60px 20px !important;
  }
  .story-card {
    padding: 15px !important;
  }
  .margin-ltsm-15 {
    margin-left: 15px;
  }

  .about-us-font {
    font-size: 18;
  }
  .login-section-height {
    min-height: 750px !important;
  }
  .ant-col-6 {
    width: 12.5% !important;
  }
  .ant-col-14 {
    width: 33.3% !important;
  }
  .kjIAYU .create-btn {
    float: left !important;
  }
  .font-sm-12 {
    font-size: 12px !important;
  }

  .font-sm-30 {
    font-size: 30px !important;
  }
  .padding-sm-10 {
    padding: 10px !important;
  }

  .ui-tooltip {
    right: 0 !important;
    left: 60% !important;
    margin-right: 20px;
    text-align: center;
    font-weight: bold;
  }
  .hs-settings-email-row {
    width: 100%;
    margin-left: 15px;
  }
  .margin-top-slogan {
    margin-top: 0px;
  }
  .our-vision-section-text {
    text-align: center;
    margin: auto;
  }
  .my-events-button-box {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 770px) {
  .bs-contain {
    background-size: 100% !important;
  }
  .login-section-height {
    min-height: 960px !important;
  }
  .ant-col-6 {
    width: 12.5% !important;
  }
  .ant-col-14 {
    width: 33.3% !important;
  }
  .kjIAYU .create-btn {
    float: left !important;
  }
  .margin-ltsm-15 {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1300px) {
  .bs-contain {
    background-size: 80% !important;
  }
  .login-section-height {
    min-height: 650px !important;
  }
  .font-resp-30 {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .video-upload-container {
    width: 100%;
  }
  .login-section-height {
    min-height: 750px !important;
  }
  .res-v-height {
    height: 250px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .video-upload-container {
    width: 60%;
  }
}

@media only screen and (max-width: 320px) {
  .logout-container {
    width: 300px;
  }

  form.search input[type="search"] {
    width: 70% !important;
  }
  .search input[type="search"] {
    width: 70% !important;
  }
}

@media only screen and (min-width: 991px) {
  .uni-list .heart-container {
    width: 5vw;
  }

  .hide-md {
    display: none !important;
  }

  .margin-top-slogan {
    margin-top: 80px;
  }

  .search-results {
    margin-left: 10px;
  }
  .search-results-google {
    margin-left: 10px;
  }

  .box {
    min-height: 50vh;
    padding:30px 0px;
    width: 500px;
    /*height: 428px;*/
  }

  /* .box-button-font{
   font-size: 1.2vw
  } */


  .about-us-font {
    font-size: 24;
  }
  .ui-tooltip {
    font-weight: bold;
  }
  .hs-settings-email-row {
    width: 57%;
    margin-left: 15px;
  }
  .team-profile-image {
    width: 200px;
    height: 200px;
  }
  .person {
    padding: 100px 250px;
  }
  .udentitylive-collapse-card {
    width: 500px;
  }
  .student-user {
    margin: 10px;
    min-width: 105px;
    height: 102px;
  }
  .features-paragraph {
    height: 400px;
  }

  .get-demo-btn {
    width: 153px;
    height: 56px;
  }
  .features-slideshow-container {
    height: 45vh;
  }

  #ready-section {
    padding: 60px 100px 60px 100px;
  }

  .upcoming-event-card {
    height: 470px;
    width: 390px;
  }

  .upcoming-event-image {
    height: 30%;
  }
  .explore-school-item {
    height: 100px;
  }

  .fair-list-item {
    display: flex;
    flex-direction: row;
  }

  .btm-container {
    height: 205px;
  }

  .btm-container-explore{
    height: 205px;
  }

  .event-card {
    width: 100%;
    height: 400px;
    padding: 10px 5px;
  }

  .event-card-explore {
    width: 100%;
    height: 400px;
    padding: 10px 5px;
  }
  .event-tag {
    padding: 3px 5px;
  }
  .fair-dummy-box {
    height: 60px;
  }

  .fair-dummy-box {
    height: 60px;
  }

  .padding-btm-50-md{
    padding-bottom:50px;
  }
  .padding-lt-30-md{
    padding-left:30px
  }
  .padding-rt-30-md{
    padding-right:30px;
  }
}

@media only screen and (max-width: 1342px) {
  .video-upload-container {
    width: 100%;
  }
}

