.box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 50vh;
  width: 30vw; */
  font-family: inherit;
  background: #fff;
  border-radius: 10px;
  /*-webkit-box-shadow: 10px 10px 39px 0px rgba(0, 0, 0, 0.65);*/
  /*-moz-box-shadow: 10px 10px 39px 0px rgba(0, 0, 0, 0.65);*/
  /*box-shadow: 10px 10px 39px 0px rgba(0, 0, 0, 0.65);*/
}
.box h1 {
  text-align: center;
  margin: 30px 0;
  font-size: 30px;
}
.box input {
  display: block;
  margin: 20px auto;
  padding: 15px;
  background: rgba(0, 0, 0, 0.2);
  color: #000;
  border: 0;
}
.box input:focus,
.box input:active,
.box button:focus,
.box button:active {
  outline: none;
}
.box button {
  background: #1771C7;
  border: 0;
  color: rgb(255, 255, 255);
  padding: 10px;
  /* font-size: 20px; */
  margin: 20px auto;
  display: block;
  cursor: pointer;
}
.box button:active {
  background: rgb(116, 121, 155);
}

.box button:hover {
  background: rgb(116, 121, 155);
}
.box p {
  font-size: 14px;
  text-align: center;
}
.box p span {
  cursor: pointer;
  color: #666;
}

.signup,
.login {
  animation: ease-in-out;
  font-weight: bold;
  color: #0f1c70;
}

.signup:hover,
.login:hover {
  cursor: pointer;
  font-weight: bold;
  opacity: 0.7;
}

.reset-password:hover {
  opacity: 0.7;
}

.modal-panel {
  display: flex;
}

.right-panel {
  margin-top: 100px;
  margin-bottom: 90px;
  padding-left: 50px;
  padding-right: 40px;
  height: fit-content;
  width: 500px;
  padding-top: 20px;
}

.change-icons-title {
  color: #757575;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.change-icons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px 10px;
  margin-bottom: -10px;
}

.change-icons > div {
    width: 175px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #1771C7;
    background: #FFF;
    color: #757575;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    margin-bottom: 0;

}

.right-panel-title {
  color: #757575;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.right-panel-text {
  color: #757575;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 15px auto;
}

.right-panel-text > span {
  text-decoration-line: underline;
}

.mobile-new {
  display: none;
}

.mobile-icon-img {
  display: none;
}

.logo-title {
  text-align: center;
  margin-bottom: 30px;
  padding-top: 30px;
}

.middle-line {
  width: 1px;
  height: 214.056px;
  background: #757575;
  align-self: center;
  margin-top: 30px;
}



@media (max-width: 991px) {

  .mobile-icon-img {
    display: block;
    text-align: center;
  }

  .mobile-icon-img > img {
    width: 160px;
    height: 35px;
  }

  .right-panel {
    width: 350px;
    height: 465px;
    margin: 0;
    padding-top: 28px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .right-panel-title {
    display: none;
  }

  .right-panel-text1 {
    display: none;
  }

  .change-icons {
    grid-template-columns: 1fr;
  }

  .change-icons > div {
    width: 100%;
  }

  .mobile-new {
    display: block;
    margin-top: 45px;
  }

  .mobile-new > div:nth-child(1) {
    color: #F6821F;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .mobile-new > div:nth-child(2) {
    color: #1771C7;
    cursor: pointer;
    text-decoration: underline;
  }

  .login-with-phone {
    color: #757575;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    width: 100%;
    margin: 5px auto;
  }
}


